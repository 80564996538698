import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { MediaUploadResponseC as imported21_MediaUploadResponseC, MediaUploadResponse as imported21_MediaUploadResponse, mediaUploadResponseC as imported21_mediaUploadResponseC } from "./media";

export type LogoPostC = t.TypeC<{
  uploadResponse: imported21_MediaUploadResponseC
}>;
export type LogoPost = {
  uploadResponse: imported21_MediaUploadResponse
};
export const logoPostC: LogoPostC = t.type({
  uploadResponse: imported21_mediaUploadResponseC
}) satisfies t.Type<LogoPost, unknown>;


export type LogoAndColorsC = t.TypeC<{
  logo: OptionFromNullableC<t.StringC>,
  logoIsRectangle: t.BooleanC,
  issuerName: t.StringC,
  subheader: OptionFromNullableC<t.StringC>,
  customHeaderText: OptionFromNullableC<t.StringC>,
  primaryColor: t.StringC,
  secondaryColor: t.StringC,
  primaryForBg: t.BooleanC
}>;
export type LogoAndColors = {
  logo: O.Option<string>,
  logoIsRectangle: boolean,
  issuerName: string,
  subheader: O.Option<string>,
  customHeaderText: O.Option<string>,
  primaryColor: string,
  secondaryColor: string,
  primaryForBg: boolean
};
export const logoAndColorsC: LogoAndColorsC = t.type({
  logo: optionFromNullable(t.string),
  logoIsRectangle: t.boolean,
  issuerName: t.string,
  subheader: optionFromNullable(t.string),
  customHeaderText: optionFromNullable(t.string),
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean
}) satisfies t.Type<LogoAndColors, unknown>;


export type LogoAndColorsPostC = t.TypeC<{
  logoUri: OptionFromNullableC<t.StringC>,
  logoIsRectangle: t.BooleanC,
  issuerName: t.StringC,
  subheader: OptionFromNullableC<t.StringC>,
  customHeaderText: OptionFromNullableC<t.StringC>,
  primaryColor: t.StringC,
  secondaryColor: t.StringC,
  primaryForBg: t.BooleanC
}>;
export type LogoAndColorsPost = {
  logoUri: O.Option<string>,
  logoIsRectangle: boolean,
  issuerName: string,
  subheader: O.Option<string>,
  customHeaderText: O.Option<string>,
  primaryColor: string,
  secondaryColor: string,
  primaryForBg: boolean
};
export const logoAndColorsPostC: LogoAndColorsPostC = t.type({
  logoUri: optionFromNullable(t.string),
  logoIsRectangle: t.boolean,
  issuerName: t.string,
  subheader: optionFromNullable(t.string),
  customHeaderText: optionFromNullable(t.string),
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean
}) satisfies t.Type<LogoAndColorsPost, unknown>;


