import type { ReactElement } from "react";
import stringify from "fast-safe-stringify";

export function JsonSyntaxHighlight(json: { json: unknown }): ReactElement {
  // eslint-disable-next-line react/no-danger
  return (<pre dangerouslySetInnerHTML={{ __html: _jsonSyntaxHighlight(json.json) }} />);
}

function _jsonSyntaxHighlight(json: unknown): string {
  return json == null
    ? "undefined"
    : stringify(json, undefined, 2) // eslint-disable-line no-undefined
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/("(\\u[a-zA-Z\d]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, (match) => {
        let cls = "number";
        if (match.startsWith("\"")) {
          if (match.endsWith(":")) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (match.includes("null")) {
          cls = "null";
        }
        return `<span class=${cls}>${match}</span>`;
      });
}
