import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { PostEmailRecipientCU as imported5_PostEmailRecipientCU, PostEmailRecipientCU as imported4_PostEmailRecipientCU, PostEmailRecipientCU as imported7_PostEmailRecipientCU, PostEmailRecipientU as imported2_PostEmailRecipientU, PostEmailRecipientCU as imported6_PostEmailRecipientCU, PostEmailRecipientU as imported3_PostEmailRecipientU } from "./email";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { LocalDateTime } from "@js-joda/core";
import { HtmlC, Html, htmlC } from "../../codecs/html";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { MediaUploadResponseC as imported23_MediaUploadResponseC, MediaUploadResponse as imported23_MediaUploadResponse, mediaUploadResponseC as imported23_mediaUploadResponseC } from "./media";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { EmailContactIdC as imported7_EmailContactIdC, ActorIdU as imported3_ActorIdU, emailContactIdC as imported5_emailContactIdC, ActorIdCU as imported6_ActorIdCU, EmailContactId as imported7_EmailContactId, ActorIdCU as imported7_ActorIdCU, UserIdC as imported8_UserIdC, userIdC as imported5_userIdC, UserId as imported8_UserId } from "./actor";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export const templateEmail = {
  _tag: `TemplateEmail`
} as const;

export type TemplateEmailTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TemplateEmail`>
}>;
export const templateEmailTaggedC: TemplateEmailTaggedC = t.type({
  _tag: t.literal(`TemplateEmail`)
});
export type TemplateEmailTagged = t.TypeOf<TemplateEmailTaggedC>;
export type TemplateEmail = TemplateEmailTagged & typeof templateEmail;
export type TemplateEmailC = t.Type<TemplateEmail, TemplateEmailTagged>;
export const templateEmailC: TemplateEmailC = pipe(templateEmailTaggedC, c => new t.Type<TemplateEmail, TemplateEmailTagged>(
  `TemplateEmail`,
  (u: unknown): u is TemplateEmail => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TemplateEmail> => pipe(c.decode(u), E.map(x => ({ ...x, ...templateEmail }))),
  (x: TemplateEmail): TemplateEmailTagged => ({ ...x, _tag: `TemplateEmail`}),
)) satisfies t.Type<TemplateEmail, unknown>;


export const customEmail = {
  _tag: `CustomEmail`
} as const;

export type CustomEmailTaggedC = t.TypeC<{
  _tag: t.LiteralC<`CustomEmail`>
}>;
export const customEmailTaggedC: CustomEmailTaggedC = t.type({
  _tag: t.literal(`CustomEmail`)
});
export type CustomEmailTagged = t.TypeOf<CustomEmailTaggedC>;
export type CustomEmail = CustomEmailTagged & typeof customEmail;
export type CustomEmailC = t.Type<CustomEmail, CustomEmailTagged>;
export const customEmailC: CustomEmailC = pipe(customEmailTaggedC, c => new t.Type<CustomEmail, CustomEmailTagged>(
  `CustomEmail`,
  (u: unknown): u is CustomEmail => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomEmail> => pipe(c.decode(u), E.map(x => ({ ...x, ...customEmail }))),
  (x: CustomEmail): CustomEmailTagged => ({ ...x, _tag: `CustomEmail`}),
)) satisfies t.Type<CustomEmail, unknown>;


export const allEmailVariantC = [templateEmailC, customEmailC] as const;
export const allEmailVariantNames = [`TemplateEmail`, `CustomEmail`] as const;
export type EmailVariantName = (typeof allEmailVariantNames)[number];

export type EmailVariantCU = t.UnionC<[TemplateEmailC, CustomEmailC]>;
export type EmailVariantU = TemplateEmail | CustomEmail;
export const EmailVariantCU: EmailVariantCU = t.union([templateEmailC, customEmailC]) satisfies t.Type<EmailVariantU, unknown>;

export const emailVariantOrd: Ord.Ord<EmailVariantU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allEmailVariant = [templateEmail, customEmail] as const;
export type EmailVariantMap<A> = { [K in EmailVariantName]: A };


export type IssuerContactEmailPostBodyC = t.TypeC<{
  recipients: ReadonlyNonEmptyArrayType<imported5_PostEmailRecipientCU>,
  subject: t.StringC,
  message: OptionFromNullableC<MarkdownC>,
  ccs: OptionFromNullableC<ReadonlyNonEmptyArrayType<t.StringC>>,
  replyTo: OptionFromNullableC<t.StringC>,
  bondOfferingId: OptionFromNullableC<t.NumberC>,
  rfpId: OptionFromNullableC<t.NumberC>,
  variant: EmailVariantCU,
  announcement: OptionFromNullableC<t.StringC>,
  featuredParticipant: OptionFromNullableC<t.StringC>
}>;
export type IssuerContactEmailPostBody = {
  recipients: RNEA.ReadonlyNonEmptyArray<imported2_PostEmailRecipientU>,
  subject: string,
  message: O.Option<Markdown>,
  ccs: O.Option<RNEA.ReadonlyNonEmptyArray<string>>,
  replyTo: O.Option<string>,
  bondOfferingId: O.Option<number>,
  rfpId: O.Option<number>,
  variant: EmailVariantU,
  announcement: O.Option<string>,
  featuredParticipant: O.Option<string>
};
export const issuerContactEmailPostBodyC: IssuerContactEmailPostBodyC = t.type({
  recipients: readonlyNonEmptyArrayC(imported4_PostEmailRecipientCU),
  subject: t.string,
  message: optionFromNullable(markdownC),
  ccs: optionFromNullable(readonlyNonEmptyArrayC(t.string)),
  replyTo: optionFromNullable(t.string),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number),
  variant: EmailVariantCU,
  announcement: optionFromNullable(t.string),
  featuredParticipant: optionFromNullable(t.string)
}) satisfies t.Type<IssuerContactEmailPostBody, unknown>;


export type BankContactEmailPostBodyC = t.TypeC<{
  recipients: ReadonlyNonEmptyArrayType<imported7_PostEmailRecipientCU>,
  subject: t.StringC,
  message: OptionFromNullableC<MarkdownC>,
  ccs: OptionFromNullableC<ReadonlyNonEmptyArrayType<t.StringC>>,
  replyTo: OptionFromNullableC<t.StringC>,
  associatedIssuerId: OptionFromNullableC<t.NumberC>,
  bondOfferingId: OptionFromNullableC<t.NumberC>,
  rfpId: OptionFromNullableC<t.NumberC>,
  variant: EmailVariantCU,
  announcement: OptionFromNullableC<t.StringC>,
  featuredParticipant: OptionFromNullableC<t.StringC>
}>;
export type BankContactEmailPostBody = {
  recipients: RNEA.ReadonlyNonEmptyArray<imported3_PostEmailRecipientU>,
  subject: string,
  message: O.Option<Markdown>,
  ccs: O.Option<RNEA.ReadonlyNonEmptyArray<string>>,
  replyTo: O.Option<string>,
  associatedIssuerId: O.Option<number>,
  bondOfferingId: O.Option<number>,
  rfpId: O.Option<number>,
  variant: EmailVariantU,
  announcement: O.Option<string>,
  featuredParticipant: O.Option<string>
};
export const bankContactEmailPostBodyC: BankContactEmailPostBodyC = t.type({
  recipients: readonlyNonEmptyArrayC(imported6_PostEmailRecipientCU),
  subject: t.string,
  message: optionFromNullable(markdownC),
  ccs: optionFromNullable(readonlyNonEmptyArrayC(t.string)),
  replyTo: optionFromNullable(t.string),
  associatedIssuerId: optionFromNullable(t.number),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number),
  variant: EmailVariantCU,
  announcement: optionFromNullable(t.string),
  featuredParticipant: optionFromNullable(t.string)
}) satisfies t.Type<BankContactEmailPostBody, unknown>;


export type EmailUserRecipientC = t.TypeC<{
  _tag: t.LiteralC<`EmailUserRecipient`>,
  id: imported8_UserIdC,
  name: t.StringC
}>;
export type EmailUserRecipient = {
  _tag: `EmailUserRecipient`,
  id: imported8_UserId,
  name: string
};
export const emailUserRecipientC: EmailUserRecipientC = t.type({
  _tag: t.literal(`EmailUserRecipient`),
  id: imported5_userIdC,
  name: t.string
}) satisfies t.Type<EmailUserRecipient, unknown>;


export type EmailContactRecipientC = t.TypeC<{
  _tag: t.LiteralC<`EmailContactRecipient`>,
  id: imported7_EmailContactIdC
}>;
export type EmailContactRecipient = {
  _tag: `EmailContactRecipient`,
  id: imported7_EmailContactId
};
export const emailContactRecipientC: EmailContactRecipientC = t.type({
  _tag: t.literal(`EmailContactRecipient`),
  id: imported5_emailContactIdC
}) satisfies t.Type<EmailContactRecipient, unknown>;


export const allEmailRecipientC = [emailUserRecipientC, emailContactRecipientC] as const;
export const allEmailRecipientNames = [`EmailUserRecipient`, `EmailContactRecipient`] as const;
export type EmailRecipientName = (typeof allEmailRecipientNames)[number];

export type EmailRecipientCU = t.UnionC<[EmailUserRecipientC, EmailContactRecipientC]>;
export type EmailRecipientU = EmailUserRecipient | EmailContactRecipient;
export const EmailRecipientCU: EmailRecipientCU = t.union([emailUserRecipientC, emailContactRecipientC]) satisfies t.Type<EmailRecipientU, unknown>;

export type EmailRecipientMap<A> = { [K in EmailRecipientName]: A };


export type EmailInfoC = t.TypeC<{
  recipient: OptionFromNullableC<EmailRecipientCU>,
  emailAddress: t.StringC,
  subject: t.StringC,
  hash: t.StringC,
  created: LocalDateTimeFromIsoStringC
}>;
export type EmailInfo = {
  recipient: O.Option<EmailRecipientU>,
  emailAddress: string,
  subject: string,
  hash: string,
  created: LocalDateTime
};
export const emailInfoC: EmailInfoC = t.type({
  recipient: optionFromNullable(EmailRecipientCU),
  emailAddress: t.string,
  subject: t.string,
  hash: t.string,
  created: LocalDateTimeFromIsoStringC
}) satisfies t.Type<EmailInfo, unknown>;


export type PreviewEmailC = t.TypeC<{
  preview: HtmlC
}>;
export type PreviewEmail = {
  preview: Html
};
export const previewEmailC: PreviewEmailC = t.type({
  preview: htmlC
}) satisfies t.Type<PreviewEmail, unknown>;


export type BulkUploadEmailsPostC = t.TypeC<{
  dryRun: t.BooleanC,
  uploadResponse: imported23_MediaUploadResponseC
}>;
export type BulkUploadEmailsPost = {
  dryRun: boolean,
  uploadResponse: imported23_MediaUploadResponse
};
export const bulkUploadEmailsPostC: BulkUploadEmailsPostC = t.type({
  dryRun: t.boolean,
  uploadResponse: imported23_mediaUploadResponseC
}) satisfies t.Type<BulkUploadEmailsPost, unknown>;


export type BulkUploadEmailsRespC = t.TypeC<{
  count: t.NumberC,
  actorIds: t.ReadonlyArrayC<imported7_ActorIdCU>,
  emails: t.ReadonlyArrayC<t.StringC>
}>;
export type BulkUploadEmailsResp = {
  count: number,
  actorIds: ReadonlyArray<imported3_ActorIdU>,
  emails: ReadonlyArray<string>
};
export const bulkUploadEmailsRespC: BulkUploadEmailsRespC = t.type({
  count: t.number,
  actorIds: t.readonlyArray(imported6_ActorIdCU),
  emails: t.readonlyArray(t.string)
}) satisfies t.Type<BulkUploadEmailsResp, unknown>;


export type SentEmailReportC = t.TypeC<{
  received: t.ReadonlyArrayC<EmailInfoC>,
  suppressed: t.ReadonlyArrayC<EmailInfoC>
}>;
export type SentEmailReport = {
  received: ReadonlyArray<EmailInfo>,
  suppressed: ReadonlyArray<EmailInfo>
};
export const sentEmailReportC: SentEmailReportC = t.type({
  received: t.readonlyArray(emailInfoC),
  suppressed: t.readonlyArray(emailInfoC)
}) satisfies t.Type<SentEmailReport, unknown>;


