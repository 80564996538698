import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const theBondBuyer = {
  _tag: `TheBondBuyer`,
  id: 3,
  name: `The Bond Buyer`
} as const;

export type TheBondBuyerTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TheBondBuyer`>
}>;
export const theBondBuyerTaggedC: TheBondBuyerTaggedC = t.type({
  _tag: t.literal(`TheBondBuyer`)
});
export type TheBondBuyerTagged = t.TypeOf<TheBondBuyerTaggedC>;
export type TheBondBuyer = TheBondBuyerTagged & typeof theBondBuyer;
export type TheBondBuyerC = t.Type<TheBondBuyer, TheBondBuyerTagged>;
export const theBondBuyerC: TheBondBuyerC = pipe(theBondBuyerTaggedC, c => new t.Type<TheBondBuyer, TheBondBuyerTagged>(
  `TheBondBuyer`,
  (u: unknown): u is TheBondBuyer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TheBondBuyer> => pipe(c.decode(u), E.map(x => ({ ...x, ...theBondBuyer }))),
  (x: TheBondBuyer): TheBondBuyerTagged => ({ ...x, _tag: `TheBondBuyer`}),
)) satisfies t.Type<TheBondBuyer, unknown>;


export const marketReports = {
  _tag: `MarketReports`,
  id: 5,
  name: `Market Reports`
} as const;

export type MarketReportsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketReports`>
}>;
export const marketReportsTaggedC: MarketReportsTaggedC = t.type({
  _tag: t.literal(`MarketReports`)
});
export type MarketReportsTagged = t.TypeOf<MarketReportsTaggedC>;
export type MarketReports = MarketReportsTagged & typeof marketReports;
export type MarketReportsC = t.Type<MarketReports, MarketReportsTagged>;
export const marketReportsC: MarketReportsC = pipe(marketReportsTaggedC, c => new t.Type<MarketReports, MarketReportsTagged>(
  `MarketReports`,
  (u: unknown): u is MarketReports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketReports> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketReports }))),
  (x: MarketReports): MarketReportsTagged => ({ ...x, _tag: `MarketReports`}),
)) satisfies t.Type<MarketReports, unknown>;


export const bloomberg = {
  _tag: `Bloomberg`,
  id: 7,
  name: `Bloomberg`
} as const;

export type BloombergTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Bloomberg`>
}>;
export const bloombergTaggedC: BloombergTaggedC = t.type({
  _tag: t.literal(`Bloomberg`)
});
export type BloombergTagged = t.TypeOf<BloombergTaggedC>;
export type Bloomberg = BloombergTagged & typeof bloomberg;
export type BloombergC = t.Type<Bloomberg, BloombergTagged>;
export const bloombergC: BloombergC = pipe(bloombergTaggedC, c => new t.Type<Bloomberg, BloombergTagged>(
  `Bloomberg`,
  (u: unknown): u is Bloomberg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Bloomberg> => pipe(c.decode(u), E.map(x => ({ ...x, ...bloomberg }))),
  (x: Bloomberg): BloombergTagged => ({ ...x, _tag: `Bloomberg`}),
)) satisfies t.Type<Bloomberg, unknown>;


export const bondLinkAnnouncements = {
  _tag: `BondLinkAnnouncements`,
  id: 8,
  name: `BondLink Announcements`
} as const;

export type BondLinkAnnouncementsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BondLinkAnnouncements`>
}>;
export const bondLinkAnnouncementsTaggedC: BondLinkAnnouncementsTaggedC = t.type({
  _tag: t.literal(`BondLinkAnnouncements`)
});
export type BondLinkAnnouncementsTagged = t.TypeOf<BondLinkAnnouncementsTaggedC>;
export type BondLinkAnnouncements = BondLinkAnnouncementsTagged & typeof bondLinkAnnouncements;
export type BondLinkAnnouncementsC = t.Type<BondLinkAnnouncements, BondLinkAnnouncementsTagged>;
export const bondLinkAnnouncementsC: BondLinkAnnouncementsC = pipe(bondLinkAnnouncementsTaggedC, c => new t.Type<BondLinkAnnouncements, BondLinkAnnouncementsTagged>(
  `BondLinkAnnouncements`,
  (u: unknown): u is BondLinkAnnouncements => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkAnnouncements> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkAnnouncements }))),
  (x: BondLinkAnnouncements): BondLinkAnnouncementsTagged => ({ ...x, _tag: `BondLinkAnnouncements`}),
)) satisfies t.Type<BondLinkAnnouncements, unknown>;


export const cbs = {
  _tag: `Cbs`,
  id: 9,
  name: `CBS`
} as const;

export type CbsTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cbs`>
}>;
export const cbsTaggedC: CbsTaggedC = t.type({
  _tag: t.literal(`Cbs`)
});
export type CbsTagged = t.TypeOf<CbsTaggedC>;
export type Cbs = CbsTagged & typeof cbs;
export type CbsC = t.Type<Cbs, CbsTagged>;
export const cbsC: CbsC = pipe(cbsTaggedC, c => new t.Type<Cbs, CbsTagged>(
  `Cbs`,
  (u: unknown): u is Cbs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cbs> => pipe(c.decode(u), E.map(x => ({ ...x, ...cbs }))),
  (x: Cbs): CbsTagged => ({ ...x, _tag: `Cbs`}),
)) satisfies t.Type<Cbs, unknown>;


export const cnn = {
  _tag: `Cnn`,
  id: 10,
  name: `CNN`
} as const;

export type CnnTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cnn`>
}>;
export const cnnTaggedC: CnnTaggedC = t.type({
  _tag: t.literal(`Cnn`)
});
export type CnnTagged = t.TypeOf<CnnTaggedC>;
export type Cnn = CnnTagged & typeof cnn;
export type CnnC = t.Type<Cnn, CnnTagged>;
export const cnnC: CnnC = pipe(cnnTaggedC, c => new t.Type<Cnn, CnnTagged>(
  `Cnn`,
  (u: unknown): u is Cnn => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cnn> => pipe(c.decode(u), E.map(x => ({ ...x, ...cnn }))),
  (x: Cnn): CnnTagged => ({ ...x, _tag: `Cnn`}),
)) satisfies t.Type<Cnn, unknown>;


export const financialTimes = {
  _tag: `FinancialTimes`,
  id: 11,
  name: `Financial Times`
} as const;

export type FinancialTimesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`FinancialTimes`>
}>;
export const financialTimesTaggedC: FinancialTimesTaggedC = t.type({
  _tag: t.literal(`FinancialTimes`)
});
export type FinancialTimesTagged = t.TypeOf<FinancialTimesTaggedC>;
export type FinancialTimes = FinancialTimesTagged & typeof financialTimes;
export type FinancialTimesC = t.Type<FinancialTimes, FinancialTimesTagged>;
export const financialTimesC: FinancialTimesC = pipe(financialTimesTaggedC, c => new t.Type<FinancialTimes, FinancialTimesTagged>(
  `FinancialTimes`,
  (u: unknown): u is FinancialTimes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialTimes> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialTimes }))),
  (x: FinancialTimes): FinancialTimesTagged => ({ ...x, _tag: `FinancialTimes`}),
)) satisfies t.Type<FinancialTimes, unknown>;


export const usaToday = {
  _tag: `UsaToday`,
  id: 12,
  name: `USA Today`
} as const;

export type UsaTodayTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UsaToday`>
}>;
export const usaTodayTaggedC: UsaTodayTaggedC = t.type({
  _tag: t.literal(`UsaToday`)
});
export type UsaTodayTagged = t.TypeOf<UsaTodayTaggedC>;
export type UsaToday = UsaTodayTagged & typeof usaToday;
export type UsaTodayC = t.Type<UsaToday, UsaTodayTagged>;
export const usaTodayC: UsaTodayC = pipe(usaTodayTaggedC, c => new t.Type<UsaToday, UsaTodayTagged>(
  `UsaToday`,
  (u: unknown): u is UsaToday => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UsaToday> => pipe(c.decode(u), E.map(x => ({ ...x, ...usaToday }))),
  (x: UsaToday): UsaTodayTagged => ({ ...x, _tag: `UsaToday`}),
)) satisfies t.Type<UsaToday, unknown>;


export const theWashingtonPost = {
  _tag: `TheWashingtonPost`,
  id: 13,
  name: `The Washington Post`
} as const;

export type TheWashingtonPostTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TheWashingtonPost`>
}>;
export const theWashingtonPostTaggedC: TheWashingtonPostTaggedC = t.type({
  _tag: t.literal(`TheWashingtonPost`)
});
export type TheWashingtonPostTagged = t.TypeOf<TheWashingtonPostTaggedC>;
export type TheWashingtonPost = TheWashingtonPostTagged & typeof theWashingtonPost;
export type TheWashingtonPostC = t.Type<TheWashingtonPost, TheWashingtonPostTagged>;
export const theWashingtonPostC: TheWashingtonPostC = pipe(theWashingtonPostTaggedC, c => new t.Type<TheWashingtonPost, TheWashingtonPostTagged>(
  `TheWashingtonPost`,
  (u: unknown): u is TheWashingtonPost => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TheWashingtonPost> => pipe(c.decode(u), E.map(x => ({ ...x, ...theWashingtonPost }))),
  (x: TheWashingtonPost): TheWashingtonPostTagged => ({ ...x, _tag: `TheWashingtonPost`}),
)) satisfies t.Type<TheWashingtonPost, unknown>;


export const theNewYorkTimes = {
  _tag: `TheNewYorkTimes`,
  id: 14,
  name: `The New York Times`
} as const;

export type TheNewYorkTimesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TheNewYorkTimes`>
}>;
export const theNewYorkTimesTaggedC: TheNewYorkTimesTaggedC = t.type({
  _tag: t.literal(`TheNewYorkTimes`)
});
export type TheNewYorkTimesTagged = t.TypeOf<TheNewYorkTimesTaggedC>;
export type TheNewYorkTimes = TheNewYorkTimesTagged & typeof theNewYorkTimes;
export type TheNewYorkTimesC = t.Type<TheNewYorkTimes, TheNewYorkTimesTagged>;
export const theNewYorkTimesC: TheNewYorkTimesC = pipe(theNewYorkTimesTaggedC, c => new t.Type<TheNewYorkTimes, TheNewYorkTimesTagged>(
  `TheNewYorkTimes`,
  (u: unknown): u is TheNewYorkTimes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TheNewYorkTimes> => pipe(c.decode(u), E.map(x => ({ ...x, ...theNewYorkTimes }))),
  (x: TheNewYorkTimes): TheNewYorkTimesTagged => ({ ...x, _tag: `TheNewYorkTimes`}),
)) satisfies t.Type<TheNewYorkTimes, unknown>;


export const marketWatch = {
  _tag: `MarketWatch`,
  id: 15,
  name: `Market Watch`
} as const;

export type MarketWatchTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketWatch`>
}>;
export const marketWatchTaggedC: MarketWatchTaggedC = t.type({
  _tag: t.literal(`MarketWatch`)
});
export type MarketWatchTagged = t.TypeOf<MarketWatchTaggedC>;
export type MarketWatch = MarketWatchTagged & typeof marketWatch;
export type MarketWatchC = t.Type<MarketWatch, MarketWatchTagged>;
export const marketWatchC: MarketWatchC = pipe(marketWatchTaggedC, c => new t.Type<MarketWatch, MarketWatchTagged>(
  `MarketWatch`,
  (u: unknown): u is MarketWatch => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketWatch> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketWatch }))),
  (x: MarketWatch): MarketWatchTagged => ({ ...x, _tag: `MarketWatch`}),
)) satisfies t.Type<MarketWatch, unknown>;


export const forbes = {
  _tag: `Forbes`,
  id: 16,
  name: `Forbes`
} as const;

export type ForbesTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Forbes`>
}>;
export const forbesTaggedC: ForbesTaggedC = t.type({
  _tag: t.literal(`Forbes`)
});
export type ForbesTagged = t.TypeOf<ForbesTaggedC>;
export type Forbes = ForbesTagged & typeof forbes;
export type ForbesC = t.Type<Forbes, ForbesTagged>;
export const forbesC: ForbesC = pipe(forbesTaggedC, c => new t.Type<Forbes, ForbesTagged>(
  `Forbes`,
  (u: unknown): u is Forbes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Forbes> => pipe(c.decode(u), E.map(x => ({ ...x, ...forbes }))),
  (x: Forbes): ForbesTagged => ({ ...x, _tag: `Forbes`}),
)) satisfies t.Type<Forbes, unknown>;


export const yahooFinance = {
  _tag: `YahooFinance`,
  id: 17,
  name: `Yahoo Finance`
} as const;

export type YahooFinanceTaggedC = t.TypeC<{
  _tag: t.LiteralC<`YahooFinance`>
}>;
export const yahooFinanceTaggedC: YahooFinanceTaggedC = t.type({
  _tag: t.literal(`YahooFinance`)
});
export type YahooFinanceTagged = t.TypeOf<YahooFinanceTaggedC>;
export type YahooFinance = YahooFinanceTagged & typeof yahooFinance;
export type YahooFinanceC = t.Type<YahooFinance, YahooFinanceTagged>;
export const yahooFinanceC: YahooFinanceC = pipe(yahooFinanceTaggedC, c => new t.Type<YahooFinance, YahooFinanceTagged>(
  `YahooFinance`,
  (u: unknown): u is YahooFinance => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, YahooFinance> => pipe(c.decode(u), E.map(x => ({ ...x, ...yahooFinance }))),
  (x: YahooFinance): YahooFinanceTagged => ({ ...x, _tag: `YahooFinance`}),
)) satisfies t.Type<YahooFinance, unknown>;


export const businessInsider = {
  _tag: `BusinessInsider`,
  id: 18,
  name: `Business Insider`
} as const;

export type BusinessInsiderTaggedC = t.TypeC<{
  _tag: t.LiteralC<`BusinessInsider`>
}>;
export const businessInsiderTaggedC: BusinessInsiderTaggedC = t.type({
  _tag: t.literal(`BusinessInsider`)
});
export type BusinessInsiderTagged = t.TypeOf<BusinessInsiderTaggedC>;
export type BusinessInsider = BusinessInsiderTagged & typeof businessInsider;
export type BusinessInsiderC = t.Type<BusinessInsider, BusinessInsiderTagged>;
export const businessInsiderC: BusinessInsiderC = pipe(businessInsiderTaggedC, c => new t.Type<BusinessInsider, BusinessInsiderTagged>(
  `BusinessInsider`,
  (u: unknown): u is BusinessInsider => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BusinessInsider> => pipe(c.decode(u), E.map(x => ({ ...x, ...businessInsider }))),
  (x: BusinessInsider): BusinessInsiderTagged => ({ ...x, _tag: `BusinessInsider`}),
)) satisfies t.Type<BusinessInsider, unknown>;


export const theWallStreetJournal = {
  _tag: `TheWallStreetJournal`,
  id: 19,
  name: `The Wall Street Journal`
} as const;

export type TheWallStreetJournalTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TheWallStreetJournal`>
}>;
export const theWallStreetJournalTaggedC: TheWallStreetJournalTaggedC = t.type({
  _tag: t.literal(`TheWallStreetJournal`)
});
export type TheWallStreetJournalTagged = t.TypeOf<TheWallStreetJournalTaggedC>;
export type TheWallStreetJournal = TheWallStreetJournalTagged & typeof theWallStreetJournal;
export type TheWallStreetJournalC = t.Type<TheWallStreetJournal, TheWallStreetJournalTagged>;
export const theWallStreetJournalC: TheWallStreetJournalC = pipe(theWallStreetJournalTaggedC, c => new t.Type<TheWallStreetJournal, TheWallStreetJournalTagged>(
  `TheWallStreetJournal`,
  (u: unknown): u is TheWallStreetJournal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TheWallStreetJournal> => pipe(c.decode(u), E.map(x => ({ ...x, ...theWallStreetJournal }))),
  (x: TheWallStreetJournal): TheWallStreetJournalTagged => ({ ...x, _tag: `TheWallStreetJournal`}),
)) satisfies t.Type<TheWallStreetJournal, unknown>;


export const theEconomist = {
  _tag: `TheEconomist`,
  id: 20,
  name: `The Economist`
} as const;

export type TheEconomistTaggedC = t.TypeC<{
  _tag: t.LiteralC<`TheEconomist`>
}>;
export const theEconomistTaggedC: TheEconomistTaggedC = t.type({
  _tag: t.literal(`TheEconomist`)
});
export type TheEconomistTagged = t.TypeOf<TheEconomistTaggedC>;
export type TheEconomist = TheEconomistTagged & typeof theEconomist;
export type TheEconomistC = t.Type<TheEconomist, TheEconomistTagged>;
export const theEconomistC: TheEconomistC = pipe(theEconomistTaggedC, c => new t.Type<TheEconomist, TheEconomistTagged>(
  `TheEconomist`,
  (u: unknown): u is TheEconomist => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TheEconomist> => pipe(c.decode(u), E.map(x => ({ ...x, ...theEconomist }))),
  (x: TheEconomist): TheEconomistTagged => ({ ...x, _tag: `TheEconomist`}),
)) satisfies t.Type<TheEconomist, unknown>;


export const allActivePublicationC = [theBondBuyerC, marketReportsC, bloombergC, bondLinkAnnouncementsC, cbsC, cnnC, financialTimesC, usaTodayC, theWashingtonPostC, theNewYorkTimesC, marketWatchC, forbesC, yahooFinanceC, businessInsiderC, theWallStreetJournalC, theEconomistC] as const;
export const allActivePublicationNames = [`TheBondBuyer`, `MarketReports`, `Bloomberg`, `BondLinkAnnouncements`, `Cbs`, `Cnn`, `FinancialTimes`, `UsaToday`, `TheWashingtonPost`, `TheNewYorkTimes`, `MarketWatch`, `Forbes`, `YahooFinance`, `BusinessInsider`, `TheWallStreetJournal`, `TheEconomist`] as const;
export type ActivePublicationName = (typeof allActivePublicationNames)[number];

export type ActivePublicationCU = t.UnionC<[TheBondBuyerC, MarketReportsC, BloombergC, BondLinkAnnouncementsC, CbsC, CnnC, FinancialTimesC, UsaTodayC, TheWashingtonPostC, TheNewYorkTimesC, MarketWatchC, ForbesC, YahooFinanceC, BusinessInsiderC, TheWallStreetJournalC, TheEconomistC]>;
export type ActivePublicationU = TheBondBuyer | MarketReports | Bloomberg | BondLinkAnnouncements | Cbs | Cnn | FinancialTimes | UsaToday | TheWashingtonPost | TheNewYorkTimes | MarketWatch | Forbes | YahooFinance | BusinessInsider | TheWallStreetJournal | TheEconomist;
export const ActivePublicationCU: ActivePublicationCU = t.union([theBondBuyerC, marketReportsC, bloombergC, bondLinkAnnouncementsC, cbsC, cnnC, financialTimesC, usaTodayC, theWashingtonPostC, theNewYorkTimesC, marketWatchC, forbesC, yahooFinanceC, businessInsiderC, theWallStreetJournalC, theEconomistC]) satisfies t.Type<ActivePublicationU, unknown>;

export const activePublicationOrd: Ord.Ord<ActivePublicationU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivePublication = [theBondBuyer, marketReports, bloomberg, bondLinkAnnouncements, cbs, cnn, financialTimes, usaToday, theWashingtonPost, theNewYorkTimes, marketWatch, forbes, yahooFinance, businessInsider, theWallStreetJournal, theEconomist] as const;
export type ActivePublicationMap<A> = { [K in ActivePublicationName]: A };


