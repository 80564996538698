import * as t from "io-ts";
import { TheseC, theseC } from "../../codecs/these";
import { LocalDateC } from "../../codecs/localDate";
import { LocalDate } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as O from "fp-ts/lib/Option";
import { ActivePublicationCU as imported3_ActivePublicationCU, ActivePublicationU as imported1_ActivePublicationU, ActivePublicationCU as imported2_ActivePublicationCU } from "../domaintables/publications";
import * as Th from "fp-ts/lib/These";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type RecentInsightUrlC = t.TypeC<{
  url: t.StringC
}>;
export type RecentInsightUrl = {
  url: string
};
export const recentInsightUrlC: RecentInsightUrlC = t.type({
  url: t.string
}) satisfies t.Type<RecentInsightUrl, unknown>;


export type RecentInsightContentC = t.TypeC<{
  content: MarkdownC
}>;
export type RecentInsightContent = {
  content: Markdown
};
export const recentInsightContentC: RecentInsightContentC = t.type({
  content: markdownC
}) satisfies t.Type<RecentInsightContent, unknown>;


export type RecentInsightC = t.TypeC<{
  publication: imported3_ActivePublicationCU,
  title: t.StringC,
  description: OptionFromNullableC<MarkdownC>,
  urlAndContent: TheseC<RecentInsightUrlC, RecentInsightContentC>,
  date: LocalDateC
}>;
export type RecentInsight = {
  publication: imported1_ActivePublicationU,
  title: string,
  description: O.Option<Markdown>,
  urlAndContent: Th.These<RecentInsightUrl, RecentInsightContent>,
  date: LocalDate
};
export const recentInsightC: RecentInsightC = t.type({
  publication: imported2_ActivePublicationCU,
  title: t.string,
  description: optionFromNullable(markdownC),
  urlAndContent: theseC(recentInsightUrlC, recentInsightContentC),
  date: LocalDateC
}) satisfies t.Type<RecentInsight, unknown>;


