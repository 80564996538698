import type { ReactElement } from "react";
import { Fragment } from "react";
import { pipe } from "fp-ts/lib/function";
import { fold, fromNullable, getOrElse } from "fp-ts/lib/Option";
import * as Th from "fp-ts/lib/These";
import type { ContextEntry, ValidationError } from "io-ts";

import { ConfigO, isProd } from "@scripts/bondlink";
import type { RespOrErrors } from "@scripts/fetch";
import { Empty, mapOrEmpty } from "@scripts/react/components/Empty";
import { JsonSyntaxHighlight } from "@scripts/ui/json";
import type { LogErrors, LogLevel } from "@scripts/util/log";
import { logErrors } from "@scripts/util/log";

const formatValidationError = (level: LogLevel) => (v: ValidationError, vIdx: number) => (
  <section key={vIdx} style={{
    background: "#fafafa",
    border: "1px dashed #333",
    padding: "1rem",
  }}
  >
    <dl>
      <dt>Message</dt>
      <dd>
        {pipe(
          fromNullable(v.message),
          getOrElse(() => "No message")
        )}
      </dd>
      <dt>Level</dt>
      <dd>{level}</dd>
      <dt>Value</dt>
      <dd><JsonSyntaxHighlight json={v.value} /></dd>
      <dt>Context</dt>
      <dd>
        {[...v.context].reverse().map((c: ContextEntry, cIdx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <dl key={cIdx} style={{
            borderTop: "0.125rem solid #999",
            marginTop: "3rem",
            paddingTop: "2rem",
          }}
          >
            <dt>Key</dt>
            <dd>
              <JsonSyntaxHighlight json={c.key} />
            </dd>
            <dt>Expected Type</dt>
            <dd>
              <pre style={{ whiteSpace: "normal" }}>{c.type.name}</pre>
            </dd>
            <dt>Actual Value</dt>
            <dd>
              <JsonSyntaxHighlight json={c.actual} />
            </dd>
          </dl>
        ))}
      </dd>
    </dl>
  </section>
);

const FormattedLogErrors = (errors: LogErrors) => {
  logErrors(errors);

  return <div>
    <h3>IO-TS Errors</h3>
    {errors.map(le => le.errors.map(formatValidationError(le.level)))}
  </div>;
};

export const FormattedRespErrors = (re: RespOrErrors): ReactElement => {
  return pipe(
    ConfigO(),
    mapOrEmpty(config => isProd(config) ? (<Empty />) : (
      <Fragment>
        {pipe(
          re,
          Th.fold(
            fold(
              () => (<h3>No response object</h3>),
              (r: Response) => (<h3>Response Error: {r.status} -- {r.statusText}</h3>)
            ),
            FormattedLogErrors,
            (_0, a) => FormattedLogErrors(a)
          )
        )}
      </Fragment>
    ))
  );
};
