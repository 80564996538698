import * as t from "io-ts";
import { MediaUploadResponseC as imported19_MediaUploadResponseC, MediaUploadResponse as imported19_MediaUploadResponse, mediaUploadResponseC as imported19_mediaUploadResponseC } from "./media";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type BankSettingsPostC = t.TypeC<{
  name: t.StringC,
  email: t.StringC,
  logo: t.StringC,
  headerImage: OptionFromNullableC<imported19_MediaUploadResponseC>,
  primaryColor: t.StringC,
  secondaryColor: t.StringC,
  primaryForBg: t.BooleanC,
  aboutText: OptionFromNullableC<MarkdownC>
}>;
export type BankSettingsPost = {
  name: string,
  email: string,
  logo: string,
  headerImage: O.Option<imported19_MediaUploadResponse>,
  primaryColor: string,
  secondaryColor: string,
  primaryForBg: boolean,
  aboutText: O.Option<Markdown>
};
export const bankSettingsPostC: BankSettingsPostC = t.type({
  name: t.string,
  email: t.string,
  logo: t.string,
  headerImage: optionFromNullable(imported19_mediaUploadResponseC),
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean,
  aboutText: optionFromNullable(markdownC)
}) satisfies t.Type<BankSettingsPost, unknown>;


