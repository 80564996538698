import { WithStatusU as imported229_WithStatusU, withIdC as imported207_withIdC, hasManyLinkC as imported92_hasManyLinkC, WithIdC as imported207_WithIdC, WithId as imported206_WithId, WithIdC as imported205_WithIdC, WithIdC as imported206_WithIdC, WithStatusCU as imported458_WithStatusCU, HasManyLink as imported92_HasManyLink, HasManyLinkC as imported92_HasManyLinkC, withIdC as imported206_withIdC, WithStatusCU as imported459_WithStatusCU, WithId as imported207_WithId, WithId as imported205_WithId, withIdC as imported205_withIdC } from "./threadThrough";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { MediaCategoryCU as imported15_MediaCategoryCU, MediaCategoryU as imported7_MediaCategoryU, MediaCategoryCU as imported14_MediaCategoryCU } from "../domaintables/mediaCategories";
import * as O from "fp-ts/lib/Option";
import { emmaDateQualifierC as imported2_emmaDateQualifierC, EmmaDateQualifierC as imported2_EmmaDateQualifierC, emmaDisclosureTypeC as imported1_emmaDisclosureTypeC, EmmaDateQualifier as imported2_EmmaDateQualifier, EmmaDisclosureTypeC as imported1_EmmaDisclosureTypeC, EmmaSubcategoryC as imported2_EmmaSubcategoryC, emmaCategoryC as imported2_emmaCategoryC, emmaSubcategoryC as imported2_emmaSubcategoryC, EmmaSubcategory as imported2_EmmaSubcategory, EmmaCategory as imported2_EmmaCategory, EmmaDisclosureType as imported1_EmmaDisclosureType, EmmaCategoryC as imported2_EmmaCategoryC } from "./emmaCategories";
import { LocalDateTime, LocalDate } from "@js-joda/core";
import { DateQualifierC as imported13_DateQualifierC, DateQualifier as imported13_DateQualifier, dateQualifierC as imported13_dateQualifierC } from "./dateQualifier";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { RelatedCusip9LinkC as imported7_RelatedCusip9LinkC, RelatedCusip9LinkC as imported6_RelatedCusip9LinkC, RelatedCusip9Link as imported7_RelatedCusip9Link, PostRelated as imported33_PostRelated, PostRelatedC as imported33_PostRelatedC, postRelatedC as imported33_postRelatedC, relatedCusip9LinkC as imported6_relatedCusip9LinkC, RelatedCusip9Link as imported6_RelatedCusip9Link, relatedCusip9LinkC as imported7_relatedCusip9LinkC } from "./relatedContent";
import { mediaUploadResponseC as imported16_mediaUploadResponseC, MediaUploadResponseC as imported17_MediaUploadResponseC, MediaUploadResponse as imported17_MediaUploadResponse, MediaUploadResponseC as imported15_MediaUploadResponseC, mediaC as imported47_mediaC, mediaUploadResponseC as imported15_mediaUploadResponseC, MediaUploadResponse as imported16_MediaUploadResponse, Media as imported48_Media, mediaUploadResponseC as imported18_mediaUploadResponseC, MediaUploadResponse as imported15_MediaUploadResponse, MediaUploadResponse as imported18_MediaUploadResponse, MediaUploadResponseC as imported16_MediaUploadResponseC, Media as imported49_Media, MediaC as imported49_MediaC, MediaUploadResponseC as imported18_MediaUploadResponseC, MediaC as imported48_MediaC, mediaC as imported46_mediaC, mediaUploadResponseC as imported17_mediaUploadResponseC } from "./media";
import { LocalDateTimeFromIsoStringC, LocalDateC } from "../../codecs/localDate";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { Cusip9C as imported5_Cusip9C, Cusip9 as imported5_Cusip9, cusip9C as imported5_cusip9C } from "./cusip";

export type DocumentTypeC = t.TypeC<{
  id: t.NumberC,
  categoryName: t.StringC
}>;
export type DocumentType = {
  id: number,
  categoryName: string
};
export const documentTypeC: DocumentTypeC = t.type({
  id: t.number,
  categoryName: t.string
}) satisfies t.Type<DocumentType, unknown>;


export type DocumentWithCategoryC = t.TypeC<{
  document: imported48_MediaC,
  category: imported15_MediaCategoryCU,
  docType: OptionFromNullableC<DocumentTypeC>
}>;
export type DocumentWithCategory = {
  document: imported48_Media,
  category: imported7_MediaCategoryU,
  docType: O.Option<DocumentType>
};
export const documentWithCategoryC: DocumentWithCategoryC = t.type({
  document: imported46_mediaC,
  category: imported14_MediaCategoryCU,
  docType: optionFromNullable(documentTypeC)
}) satisfies t.Type<DocumentWithCategory, unknown>;


export type EmmaReceiptC = t.TypeC<{
  emmaSubmissionId: t.StringC,
  created: LocalDateTimeFromIsoStringC
}>;
export type EmmaReceipt = {
  emmaSubmissionId: string,
  created: LocalDateTime
};
export const emmaReceiptC: EmmaReceiptC = t.type({
  emmaSubmissionId: t.string,
  created: LocalDateTimeFromIsoStringC
}) satisfies t.Type<EmmaReceipt, unknown>;


export type EmmaDocumentRelatedContentC = t.TypeC<{
  cusip9s: t.ReadonlyArrayC<imported92_HasManyLinkC<imported459_WithStatusCU<imported5_Cusip9C>, imported6_RelatedCusip9LinkC>>,
  receipts: t.ReadonlyArrayC<imported206_WithIdC<EmmaReceiptC>>
}>;
export type EmmaDocumentRelatedContent = {
  cusip9s: ReadonlyArray<imported92_HasManyLink<imported229_WithStatusU<imported5_Cusip9>, imported6_RelatedCusip9Link>>,
  receipts: ReadonlyArray<imported206_WithId<EmmaReceipt>>
};
export const emmaDocumentRelatedContentC: EmmaDocumentRelatedContentC = t.type({
  cusip9s: t.readonlyArray(imported92_hasManyLinkC(imported458_WithStatusCU(imported5_cusip9C), imported6_relatedCusip9LinkC)),
  receipts: t.readonlyArray(imported206_withIdC(emmaReceiptC))
}) satisfies t.Type<EmmaDocumentRelatedContent, unknown>;


export type EmmaContactInfoC = t.TypeC<{
  address: t.StringC,
  city: t.StringC,
  state: t.StringC,
  postalCode: t.StringC,
  organizationName: t.StringC,
  firstName: t.StringC,
  lastName: t.StringC,
  email: t.StringC,
  phoneNumber: t.StringC,
  phoneExtension: OptionFromNullableC<t.StringC>
}>;
export type EmmaContactInfo = {
  address: string,
  city: string,
  state: string,
  postalCode: string,
  organizationName: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  phoneExtension: O.Option<string>
};
export const emmaContactInfoC: EmmaContactInfoC = t.type({
  address: t.string,
  city: t.string,
  state: t.string,
  postalCode: t.string,
  organizationName: t.string,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: t.string,
  phoneExtension: optionFromNullable(t.string)
}) satisfies t.Type<EmmaContactInfo, unknown>;


export type EmmaDataC = t.TypeC<{
  submissionId: OptionFromNullableC<t.StringC>,
  asOfDate: OptionFromNullableC<LocalDateC>,
  beginDate: OptionFromNullableC<LocalDateC>,
  endDate: OptionFromNullableC<LocalDateC>,
  categoryDateDescription: t.StringC,
  extraDescription: OptionFromNullableC<t.StringC>,
  disclosureType: imported1_EmmaDisclosureTypeC,
  category: imported2_EmmaCategoryC,
  subcategory: OptionFromNullableC<imported2_EmmaSubcategoryC>,
  timePeriod: imported2_EmmaDateQualifierC,
  contactInfo: EmmaContactInfoC
}>;
export type EmmaData = {
  submissionId: O.Option<string>,
  asOfDate: O.Option<LocalDate>,
  beginDate: O.Option<LocalDate>,
  endDate: O.Option<LocalDate>,
  categoryDateDescription: string,
  extraDescription: O.Option<string>,
  disclosureType: imported1_EmmaDisclosureType,
  category: imported2_EmmaCategory,
  subcategory: O.Option<imported2_EmmaSubcategory>,
  timePeriod: imported2_EmmaDateQualifier,
  contactInfo: EmmaContactInfo
};
export const emmaDataC: EmmaDataC = t.type({
  submissionId: optionFromNullable(t.string),
  asOfDate: optionFromNullable(LocalDateC),
  beginDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  categoryDateDescription: t.string,
  extraDescription: optionFromNullable(t.string),
  disclosureType: imported1_emmaDisclosureTypeC,
  category: imported2_emmaCategoryC,
  subcategory: optionFromNullable(imported2_emmaSubcategoryC),
  timePeriod: imported2_emmaDateQualifierC,
  contactInfo: emmaContactInfoC
}) satisfies t.Type<EmmaData, unknown>;


export type EmmaDocumentWithRelatedContentC = t.TypeC<{
  emmaDoc: EmmaDataC,
  relatedContent: EmmaDocumentRelatedContentC
}>;
export type EmmaDocumentWithRelatedContent = {
  emmaDoc: EmmaData,
  relatedContent: EmmaDocumentRelatedContent
};
export const emmaDocumentWithRelatedContentC: EmmaDocumentWithRelatedContentC = t.type({
  emmaDoc: emmaDataC,
  relatedContent: emmaDocumentRelatedContentC
}) satisfies t.Type<EmmaDocumentWithRelatedContent, unknown>;


export type DocumentWithEmmaDataC = t.TypeC<{
  document: DocumentWithCategoryC,
  emmaData: OptionFromNullableC<imported205_WithIdC<EmmaDocumentWithRelatedContentC>>
}>;
export type DocumentWithEmmaData = {
  document: DocumentWithCategory,
  emmaData: O.Option<imported205_WithId<EmmaDocumentWithRelatedContent>>
};
export const documentWithEmmaDataC: DocumentWithEmmaDataC = t.type({
  document: documentWithCategoryC,
  emmaData: optionFromNullable(imported205_withIdC(emmaDocumentWithRelatedContentC))
}) satisfies t.Type<DocumentWithEmmaData, unknown>;


export type DocumentTypePostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  categoryName: t.StringC
}>;
export type DocumentTypePost = {
  id: O.Option<number>,
  categoryName: string
};
export const documentTypePostC: DocumentTypePostC = t.type({
  id: optionFromNullable(t.number),
  categoryName: t.string
}) satisfies t.Type<DocumentTypePost, unknown>;


export type DocumentPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  archived: t.NumberC,
  caption: OptionFromNullableC<t.StringC>,
  altText: OptionFromNullableC<t.StringC>,
  mediaCategoryId: OptionFromNullableC<t.NumberC>,
  documentTypeId: OptionFromNullableC<t.NumberC>,
  mediaDate: imported13_DateQualifierC,
  mediaOrder: t.NumberC,
  uploadResponse: imported15_MediaUploadResponseC,
  esgRelated: t.BooleanC
}>;
export type DocumentPostBody = {
  id: O.Option<number>,
  archived: number,
  caption: O.Option<string>,
  altText: O.Option<string>,
  mediaCategoryId: O.Option<number>,
  documentTypeId: O.Option<number>,
  mediaDate: imported13_DateQualifier,
  mediaOrder: number,
  uploadResponse: imported15_MediaUploadResponse,
  esgRelated: boolean
};
export const documentPostBodyC: DocumentPostBodyC = t.type({
  id: optionFromNullable(t.number),
  archived: t.number,
  caption: optionFromNullable(t.string),
  altText: optionFromNullable(t.string),
  mediaCategoryId: optionFromNullable(t.number),
  documentTypeId: optionFromNullable(t.number),
  mediaDate: imported13_dateQualifierC,
  mediaOrder: t.number,
  uploadResponse: imported15_mediaUploadResponseC,
  esgRelated: t.boolean
}) satisfies t.Type<DocumentPostBody, unknown>;


export type EmmaDetailsC = t.TypeC<{
  disclosureTypeId: t.NumberC,
  categoryId: t.NumberC,
  subcategoryId: OptionFromNullableC<t.NumberC>,
  description: OptionFromNullableC<t.StringC>,
  timePeriodId: t.NumberC,
  beginDate: OptionFromNullableC<LocalDateC>,
  endDate: OptionFromNullableC<LocalDateC>,
  asOfDate: OptionFromNullableC<LocalDateC>,
  contact: EmmaContactInfoC
}>;
export type EmmaDetails = {
  disclosureTypeId: number,
  categoryId: number,
  subcategoryId: O.Option<number>,
  description: O.Option<string>,
  timePeriodId: number,
  beginDate: O.Option<LocalDate>,
  endDate: O.Option<LocalDate>,
  asOfDate: O.Option<LocalDate>,
  contact: EmmaContactInfo
};
export const emmaDetailsC: EmmaDetailsC = t.type({
  disclosureTypeId: t.number,
  categoryId: t.number,
  subcategoryId: optionFromNullable(t.number),
  description: optionFromNullable(t.string),
  timePeriodId: t.number,
  beginDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  asOfDate: optionFromNullable(LocalDateC),
  contact: emmaContactInfoC
}) satisfies t.Type<EmmaDetails, unknown>;


export type EmmaDocC = t.TypeC<{
  published: t.BooleanC,
  document: imported207_WithIdC<imported49_MediaC>
}>;
export type EmmaDoc = {
  published: boolean,
  document: imported207_WithId<imported49_Media>
};
export const emmaDocC: EmmaDocC = t.type({
  published: t.boolean,
  document: imported207_withIdC(imported47_mediaC)
}) satisfies t.Type<EmmaDoc, unknown>;


export type EmmaDocumentPostRelatedContentC = t.TypeC<{
  cusip9s: imported33_PostRelatedC<imported7_RelatedCusip9LinkC>
}>;
export type EmmaDocumentPostRelatedContent = {
  cusip9s: imported33_PostRelated<imported7_RelatedCusip9Link>
};
export const emmaDocumentPostRelatedContentC: EmmaDocumentPostRelatedContentC = t.type({
  cusip9s: imported33_postRelatedC(imported7_relatedCusip9LinkC)
}) satisfies t.Type<EmmaDocumentPostRelatedContent, unknown>;


export type EmmaDocPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  media: DocumentPostBodyC,
  emmaDoc: EmmaDetailsC,
  makeContactDefault: OptionFromNullableC<t.BooleanC>,
  relatedContent: EmmaDocumentPostRelatedContentC
}>;
export type EmmaDocPostBody = {
  id: O.Option<number>,
  media: DocumentPostBody,
  emmaDoc: EmmaDetails,
  makeContactDefault: O.Option<boolean>,
  relatedContent: EmmaDocumentPostRelatedContent
};
export const emmaDocPostBodyC: EmmaDocPostBodyC = t.type({
  id: optionFromNullable(t.number),
  media: documentPostBodyC,
  emmaDoc: emmaDetailsC,
  makeContactDefault: optionFromNullable(t.boolean),
  relatedContent: emmaDocumentPostRelatedContentC
}) satisfies t.Type<EmmaDocPostBody, unknown>;


export type IrmaLetterPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  uploadResponse: imported16_MediaUploadResponseC
}>;
export type IrmaLetterPost = {
  id: O.Option<number>,
  uploadResponse: imported16_MediaUploadResponse
};
export const irmaLetterPostC: IrmaLetterPostC = t.type({
  id: optionFromNullable(t.number),
  uploadResponse: imported16_mediaUploadResponseC
}) satisfies t.Type<IrmaLetterPost, unknown>;


export type DocumentCategoryIdC = t.TypeC<{
  documentTypeId: OptionFromNullableC<t.NumberC>,
  mediaCategoryId: OptionFromNullableC<t.NumberC>
}>;
export type DocumentCategoryId = {
  documentTypeId: O.Option<number>,
  mediaCategoryId: O.Option<number>
};
export const documentCategoryIdC: DocumentCategoryIdC = t.type({
  documentTypeId: optionFromNullable(t.number),
  mediaCategoryId: optionFromNullable(t.number)
}) satisfies t.Type<DocumentCategoryId, unknown>;


export type SortDocCategoriesC = t.TypeC<{
  categories: ReadonlyNonEmptyArrayType<DocumentCategoryIdC>
}>;
export type SortDocCategories = {
  categories: RNEA.ReadonlyNonEmptyArray<DocumentCategoryId>
};
export const sortDocCategoriesC: SortDocCategoriesC = t.type({
  categories: readonlyNonEmptyArrayC(documentCategoryIdC)
}) satisfies t.Type<SortDocCategories, unknown>;


export type OfferingPrivateDocumentPostBodyC = t.TypeC<{
  offeringId: t.NumberC,
  uploadResponse: imported17_MediaUploadResponseC
}>;
export type OfferingPrivateDocumentPostBody = {
  offeringId: number,
  uploadResponse: imported17_MediaUploadResponse
};
export const offeringPrivateDocumentPostBodyC: OfferingPrivateDocumentPostBodyC = t.type({
  offeringId: t.number,
  uploadResponse: imported17_mediaUploadResponseC
}) satisfies t.Type<OfferingPrivateDocumentPostBody, unknown>;


export type RfpPrivateDocumentPostBodyC = t.TypeC<{
  rfpId: t.NumberC,
  uploadResponse: imported18_MediaUploadResponseC
}>;
export type RfpPrivateDocumentPostBody = {
  rfpId: number,
  uploadResponse: imported18_MediaUploadResponse
};
export const rfpPrivateDocumentPostBodyC: RfpPrivateDocumentPostBodyC = t.type({
  rfpId: t.number,
  uploadResponse: imported18_mediaUploadResponseC
}) satisfies t.Type<RfpPrivateDocumentPostBody, unknown>;


