import * as t from "io-ts";
import { mediaUploadResponseC as imported25_mediaUploadResponseC, MediaUploadResponseC as imported25_MediaUploadResponseC, MediaUploadResponse as imported25_MediaUploadResponse, mediaC as imported71_mediaC, MediaC as imported75_MediaC, Media as imported75_Media } from "./media";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type OfficerC = t.TypeC<{
  firstName: t.StringC,
  lastName: t.StringC,
  title: t.StringC,
  email: OptionFromNullableC<t.StringC>,
  phoneNumber: OptionFromNullableC<t.StringC>,
  phoneExtension: OptionFromNullableC<t.StringC>,
  twitterUrl: OptionFromNullableC<t.StringC>,
  linkedinUrl: OptionFromNullableC<t.StringC>,
  blurb: OptionFromNullableC<MarkdownC>,
  issuerOrder: t.NumberC,
  officerPageVisibility: t.BooleanC,
  media: OptionFromNullableC<imported75_MediaC>
}>;
export type Officer = {
  firstName: string,
  lastName: string,
  title: string,
  email: O.Option<string>,
  phoneNumber: O.Option<string>,
  phoneExtension: O.Option<string>,
  twitterUrl: O.Option<string>,
  linkedinUrl: O.Option<string>,
  blurb: O.Option<Markdown>,
  issuerOrder: number,
  officerPageVisibility: boolean,
  media: O.Option<imported75_Media>
};
export const officerC: OfficerC = t.type({
  firstName: t.string,
  lastName: t.string,
  title: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  blurb: optionFromNullable(markdownC),
  issuerOrder: t.number,
  officerPageVisibility: t.boolean,
  media: optionFromNullable(imported71_mediaC)
}) satisfies t.Type<Officer, unknown>;


export type OfficerPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  firstName: t.StringC,
  lastName: t.StringC,
  title: t.StringC,
  email: OptionFromNullableC<t.StringC>,
  phoneNumber: OptionFromNullableC<t.StringC>,
  phoneExtension: OptionFromNullableC<t.StringC>,
  twitterUrl: OptionFromNullableC<t.StringC>,
  linkedinUrl: OptionFromNullableC<t.StringC>,
  blurb: OptionFromNullableC<MarkdownC>,
  issuerOrder: t.NumberC,
  officerPageVisibility: t.BooleanC,
  media: OptionFromNullableC<imported25_MediaUploadResponseC>
}>;
export type OfficerPostBody = {
  id: O.Option<number>,
  firstName: string,
  lastName: string,
  title: string,
  email: O.Option<string>,
  phoneNumber: O.Option<string>,
  phoneExtension: O.Option<string>,
  twitterUrl: O.Option<string>,
  linkedinUrl: O.Option<string>,
  blurb: O.Option<Markdown>,
  issuerOrder: number,
  officerPageVisibility: boolean,
  media: O.Option<imported25_MediaUploadResponse>
};
export const officerPostBodyC: OfficerPostBodyC = t.type({
  id: optionFromNullable(t.number),
  firstName: t.string,
  lastName: t.string,
  title: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  blurb: optionFromNullable(markdownC),
  issuerOrder: t.number,
  officerPageVisibility: t.boolean,
  media: optionFromNullable(imported25_mediaUploadResponseC)
}) satisfies t.Type<OfficerPostBody, unknown>;


export type PostPrimaryOfficerC = t.TypeC<{
  id: t.NumberC
}>;
export type PostPrimaryOfficer = {
  id: number
};
export const postPrimaryOfficerC: PostPrimaryOfficerC = t.type({
  id: t.number
}) satisfies t.Type<PostPrimaryOfficer, unknown>;


export type WelcomeLetterSigneeC = t.TypeC<{
  welcomeSigneeId: OptionFromNullableC<t.NumberC>
}>;
export type WelcomeLetterSignee = {
  welcomeSigneeId: O.Option<number>
};
export const welcomeLetterSigneeC: WelcomeLetterSigneeC = t.type({
  welcomeSigneeId: optionFromNullable(t.number)
}) satisfies t.Type<WelcomeLetterSignee, unknown>;


