import { WithIdC as imported260_WithIdC, WithIdC as imported261_WithIdC, WithId as imported261_WithId, withIdC as imported260_withIdC, WithId as imported260_WithId, withIdC as imported261_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { UserPersonaCU as imported5_UserPersonaCU, UserPersonaU as imported2_UserPersonaU, UserPersonaCU as imported4_UserPersonaCU } from "../domaintables/userPersonas";
import { mediaUploadResponseC as imported22_mediaUploadResponseC, mediaC as imported67_mediaC, MediaUploadResponse as imported22_MediaUploadResponse, MediaC as imported71_MediaC, MediaUploadResponseC as imported22_MediaUploadResponseC, Media as imported71_Media } from "./media";
import * as O from "fp-ts/lib/Option";
import { ParticipantTypeC as imported11_ParticipantTypeC, ParticipantType as imported11_ParticipantType, participantTypeC as imported11_participantTypeC } from "./participantType";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export type OfferingParticipantC = t.TypeC<{
  name: t.StringC,
  url: OptionFromNullableC<t.StringC>,
  contactName: OptionFromNullableC<t.StringC>,
  contactPhone: OptionFromNullableC<t.StringC>,
  contactEmail: OptionFromNullableC<t.StringC>,
  sortOrder: t.NumberC,
  photo: OptionFromNullableC<imported260_WithIdC<imported71_MediaC>>,
  commonParticipantId: OptionFromNullableC<t.NumberC>
}>;
export type OfferingParticipant = {
  name: string,
  url: O.Option<string>,
  contactName: O.Option<string>,
  contactPhone: O.Option<string>,
  contactEmail: O.Option<string>,
  sortOrder: number,
  photo: O.Option<imported260_WithId<imported71_Media>>,
  commonParticipantId: O.Option<number>
};
export const offeringParticipantC: OfferingParticipantC = t.type({
  name: t.string,
  url: optionFromNullable(t.string),
  contactName: optionFromNullable(t.string),
  contactPhone: optionFromNullable(t.string),
  contactEmail: optionFromNullable(t.string),
  sortOrder: t.number,
  photo: optionFromNullable(imported260_withIdC(imported67_mediaC)),
  commonParticipantId: optionFromNullable(t.number)
}) satisfies t.Type<OfferingParticipant, unknown>;


export type OfferingParticipantWithTypeC = t.TypeC<{
  participant: OfferingParticipantC,
  type: imported261_WithIdC<imported11_ParticipantTypeC>
}>;
export type OfferingParticipantWithType = {
  participant: OfferingParticipant,
  type: imported261_WithId<imported11_ParticipantType>
};
export const offeringParticipantWithTypeC: OfferingParticipantWithTypeC = t.type({
  participant: offeringParticipantC,
  type: imported261_withIdC(imported11_participantTypeC)
}) satisfies t.Type<OfferingParticipantWithType, unknown>;


export type OfferingParticipantPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  contactEmail: OptionFromNullableC<t.StringC>,
  contactName: OptionFromNullableC<t.StringC>,
  contactPhone: OptionFromNullableC<t.StringC>,
  fileSize: OptionFromNullableC<t.NumberC>,
  logoUri: OptionFromNullableC<t.StringC>,
  mediaId: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  url: OptionFromNullableC<t.StringC>,
  commonParticipantId: OptionFromNullableC<t.NumberC>,
  sortOrder: t.NumberC
}>;
export type OfferingParticipantPostBody = {
  id: O.Option<number>,
  contactEmail: O.Option<string>,
  contactName: O.Option<string>,
  contactPhone: O.Option<string>,
  fileSize: O.Option<number>,
  logoUri: O.Option<string>,
  mediaId: O.Option<number>,
  name: string,
  url: O.Option<string>,
  commonParticipantId: O.Option<number>,
  sortOrder: number
};
export const offeringParticipantPostBodyC: OfferingParticipantPostBodyC = t.type({
  id: optionFromNullable(t.number),
  contactEmail: optionFromNullable(t.string),
  contactName: optionFromNullable(t.string),
  contactPhone: optionFromNullable(t.string),
  fileSize: optionFromNullable(t.number),
  logoUri: optionFromNullable(t.string),
  mediaId: optionFromNullable(t.number),
  name: t.string,
  url: optionFromNullable(t.string),
  commonParticipantId: optionFromNullable(t.number),
  sortOrder: t.number
}) satisfies t.Type<OfferingParticipantPostBody, unknown>;


export type CommonParticipantC = t.TypeC<{
  organizationType: imported5_UserPersonaCU,
  name: t.StringC,
  logo: imported22_MediaUploadResponseC,
  url: OptionFromNullableC<t.StringC>,
  featured: t.BooleanC
}>;
export type CommonParticipant = {
  organizationType: imported2_UserPersonaU,
  name: string,
  logo: imported22_MediaUploadResponse,
  url: O.Option<string>,
  featured: boolean
};
export const commonParticipantC: CommonParticipantC = t.type({
  organizationType: imported4_UserPersonaCU,
  name: t.string,
  logo: imported22_mediaUploadResponseC,
  url: optionFromNullable(t.string),
  featured: t.boolean
}) satisfies t.Type<CommonParticipant, unknown>;


